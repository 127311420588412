import { writable, derived, get } from "svelte/store";
function createLocalStorageStore(key, startValue) {
    const storedValue = localStorage.getItem(key);
    let initialValue = startValue;
    if (storedValue) {
        try {
            initialValue = JSON.parse(storedValue);
        }
        catch (_a) {
            console.log('Bad value stored', storedValue, 'for key', key, 'removing');
            localStorage.removeItem(key);
        }
    }
    const store = writable(initialValue);
    store.subscribe((value) => localStorage.setItem(key, JSON.stringify(value)));
    return store;
}
export let selectedSchedule = createLocalStorageStore('schedule', '');
export let school = createLocalStorageStore('school', "All");
school.subscribe((value) => {
    let $selectedSchedule = get(selectedSchedule);
    console.log('School changed to', value);
    if (value == 'HS') {
        selectedSchedule.set('HS');
    }
    else if ($selectedSchedule == 'HS' && value != 'All') {
        selectedSchedule.set('5/6 Simple');
    }
});
export let prefsSet = derived([school], ([$school]) => {
    let val = !!$school;
    console.log('school was ', $school, 'return', val);
    return val;
});
export let activeCardIDs = writable([]);
export let hiddenCards = createLocalStorageStore('hidden', {});
export let customSchedule = createLocalStorageStore('customSchedule', {});
export let showPrefs = writable(false);
export function getCustomBlockName(sched, block) {
    let custom = get(customSchedule);
    if (!custom[sched]) {
        return null;
    }
    else {
        let customSched = custom[sched];
        if (customSched.byDay && customSched.byDay[block.day]
            && customSched.byDay[block.day][block.name]) {
            return customSched.byDay[block.day][block.name];
        }
        else if (customSched.byBlock && customSched.byBlock[block.name]) {
            return customSched.byBlock[block.name];
        }
        else {
            return null;
        }
    }
}
export const students = createLocalStorageStore('students', []);
export const whimsy = createLocalStorageStore('whimsy', true);
export const preferredCountdownName = createLocalStorageStore('countdown', undefined);
export const hideCountdown = createLocalStorageStore('hide-countdown', false);
export const collapsedMenus = createLocalStorageStore('collapsed-menu-items', {});
export const hiddenMenuItems = createLocalStorageStore('hidden-menus', {});
export const customColors = createLocalStorageStore('custom-colors', {});
