import { GASURL } from "../shimURL";
import { CachedDataStore } from "../util/dataFetcher";
let url = `${GASURL}&cards=true`;
export const cardFetcher = new CachedDataStore({
    url,
    name: "remote-cards",
    expiresAfter: 60 * 60 * 1000,
    defaultValue: [],
});
export async function fetchCards() {
    try {
        let request = await fetch(url, { redirect: "follow" });
        let cards = await request.json();
        console.log("Got Cards...", cards);
        return cards;
    }
    catch (err) {
        console.log("Error fetching", url);
        console.log(err);
        console.log(":(");
        throw err;
    }
}
