<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
  <!-- Outer Circle -->

  <!-- Color Circles -->
  <circle cx="12" cy="2" r="4" class="red" />
  <circle cx="15.59" cy="3.41" r="4" class="pink" />
  <circle cx="18.36" cy="6.18" r="4" class="purple" />
  <circle cx="20" cy="10" r="4" class="deep-purple" />
  <circle cx="18.36" cy="13.82" r="4" class="indigo" />
  <circle cx="15.59" cy="16.59" r="4" class="blue" />
  <circle cx="12" cy="18" r="4" class="light-blue" />
  <circle cx="8.41" cy="16.59" r="4" class="cyan" />
  <circle cx="5.64" cy="13.82" r="4" class="teal" />
  <circle cx="4" cy="10" r="4" class="green" />
  <circle cx="5.64" cy="6.18" r="4" class="light-green" />
  <circle cx="8.41" cy="3.41" r="4" class="lime" />
  <circle cx="12" cy="2" r="4" class="yellow" />
  <circle cx="15.59" cy="3.41" r="4" class="amber" />
  <circle cx="18.36" cy="6.18" r="4" class="orange" />
  <circle cx="20" cy="10" r="4" class="deep-orange" />
</svg>

<style>
  .red {
    fill: var(--material-color-red);
  }
  .pink {
    fill: var(--material-color-pink);
  }
  .purple {
    fill: var(--material-color-purple);
  }
  .deep-purple {
    fill: var(--material-color-deep-purple);
  }
  .indigo {
    fill: var(--material-color-indigo);
  }
  .blue {
    fill: var(--material-color-blue);
  }
  .light-blue {
    fill: var(--material-color-light-blue);
  }
  .cyan {
    fill: var(--material-color-cyan);
  }
  .teal {
    fill: var(--material-color-teal);
  }
  .green {
    fill: var(--material-color-green);
  }
  .light-green {
    fill: var(--material-color-light-green);
  }
  .lime {
    fill: var(--material-color-lime);
  }
  .yellow {
    fill: var(--material-color-yellow);
  }
  .amber {
    fill: var(--material-color-amber);
  }
  .orange {
    fill: var(--material-color-orange);
  }
  .deep-orange {
    fill: var(--material-color-deep-orange);
  }
  svg {
    width: 32px;
    height: 32px;
  }
</style>
